
import { useEffect, useState } from 'react'
import { getCorrectDateFormat, getSubCategory, rngPassword } from '../../helper/helper'

export default function TransactionWeeklySummary({ type, documents, currentMonthObj, currentWeek }) {

    const [subCat, setSubCat] = useState(null)
    const [transactions, setTransactions] = useState([])
    const [transactionsTotal, setTransactionsTotal] = useState([])


    useEffect(() => {
        setSubCat(getSubCategory(type))

    }, [type])

    useEffect(() => {
        setTransactions([])
        setTransactionsTotal(0)
        
        
        

        let trColl = [];
        documents && documents.map(doc => {
            const docDay = new Date(doc.day.toDate())

            const darrsd = currentMonthObj[currentWeek][0].split('-')
            const darred = currentMonthObj[currentWeek][6].split('-')
            const sd = getCorrectDateFormat({
                day: darrsd[1],
                month: darrsd[0],
                year: darrsd[2]
            })
            const ed = getCorrectDateFormat({
                day: darred[1],
                month: darred[0],
                year: darred[2]
            })

            if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime() && doc.category === type){
                trColl.push(doc)
            }

            return null
        })

        
        // console.log('trColl', type, trColl)
        trColl && trColl.map(tr => {
            if(tr.category === type){
                setTransactionsTotal(ps => ps + parseFloat(tr.amount) )
            }
            
            return null
        })

        
        setTransactions(trColl)

    }, [documents, currentMonthObj, currentWeek, type])


    return (
        <>
            {subCat &&
                
                <div key={rngPassword()} className={`weekly-summary-con flex-col-center-start ${type}`}>
                    <span className="title flex-row-center-center">{subCat.title}</span>
                    <div className="content">
                        { transactions && subCat && subCat.data.map(sc =>  {
                            let na = 0
                            transactions.map(tr => {
                                if(tr.subcategory === sc.id){
                                    na += parseFloat(tr.amount)
                                }
                                return null
                            })
                            return (
                                <div key={sc.id} className="widget flex-row-end-between">
                                    <span>{sc.name}</span>
                                    <span>${na}</span>
                                </div>
                            )
                        })}
                        <div className="widget flex-row-center-between">
                            <span >Total</span>
                            <span>${transactionsTotal}</span>
                        </div>
                    </div>
                </div>
            }
        </>
        
    )
}
