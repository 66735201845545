import MobileDataWidgetCardContentWidget from "./MobileDataWidgetCardContentWidget";


export default function MobileDataWidgetCardContent({ transactions, isClosed }) {
    
    

    

    return (
        <div className="content">
        {
            transactions && !isClosed && transactions.map(transaction => 
                
                <MobileDataWidgetCardContentWidget 
                    transaction={transaction}
                    key={transaction.id}
                />
            )
        }
        {!transactions.length && !isClosed &&
            <div key="empty" className="widget flex-row-center-center">
                <span className="empty">Empty</span>
            </div>
        }
        </div>
        
    )
}
