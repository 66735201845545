
import { useEffect, useState } from "react"
import { getCorrectDateFormat } from "../../../helper/helper"
import MobileDateWidgetCard from "./MobileDateWidgetCard"

export default function MobileWidgetCardContainer({ closeAllTransactions, documents, selectedDate }) {

    // console.log(documents)

    const [essentialTransactions, setEssentialTransactions] = useState([])
    const [optionalTransactions, setOptionalTransactions] = useState([])
    const [leisureTransactions, setLeisureTransactions] = useState([])
    const [extrasTransactions, setExtrasTransactions] = useState([])



    useEffect(() => {
        setEssentialTransactions([])
        setOptionalTransactions([])
        setLeisureTransactions([])
        setExtrasTransactions([])


        let trColl = [];
        documents && documents.map(doc => {
            const docDay = new Date(doc.day.toDate())
            const selDay = getCorrectDateFormat({
                day: selectedDate.split('-')[1],
                month: selectedDate.split('-')[0],
                year: selectedDate.split('-')[2]
            })

            if(docDay.getTime() === selDay.getTime()){
                trColl.push(doc)
            }

            return null
        })
        
        // console.log('trColl', trColl)
        trColl && trColl.forEach(tr => {
            if(tr.category === 'essential'){
                setEssentialTransactions(prevState => [...prevState, tr])
            }
            if(tr.category === 'optional'){
                setOptionalTransactions(prevState => [...prevState, tr])
            }
            if(tr.category === 'leisure'){
                setLeisureTransactions(prevState => [...prevState, tr])
            }
            if(tr.category === 'extras'){
                setExtrasTransactions(prevState => [...prevState, tr])
            }
            // console.log('transactionTotal', transactionTotal);
        })

    }, [documents, selectedDate])


    return (
        <>
           <MobileDateWidgetCard 
                closeAllTransactions={closeAllTransactions}
                type={'essential'}
                transactions={essentialTransactions}
                selectedDate={selectedDate}
            />
            <MobileDateWidgetCard 
                closeAllTransactions={closeAllTransactions}
                type={'optional'}
                transactions={optionalTransactions}
                selectedDate={selectedDate}
            />
            <MobileDateWidgetCard 
                closeAllTransactions={closeAllTransactions}
                type={'leisure'}
                transactions={leisureTransactions}
                selectedDate={selectedDate}
            />
            <MobileDateWidgetCard 
                closeAllTransactions={closeAllTransactions}
                type={'extras'}
                transactions={extrasTransactions}
                selectedDate={selectedDate}
            /> 
        </>
    )
}
