import { useReducer, useEffect, useState } from "react";
import { projectFirestore, timestamp } from "../firebase/config";


let initState = {
    document: null,
    isPending: false,
    error: null,
    success: null
}

const firestoreReducer =(state, action)=>{
    switch (action.type) {
        case 'IS_PENDING':
            return {isPending: true, document: null, success: false, error: null}
        case 'ADDED_DOC':
            return { isPending: false, document: action.payload, success: true, error: null}
        case 'DELETED_DOC':
            return { isPending: false, document: null, success: true, error: null}
        case 'UPDATED_DOC':
            return { isPending: false, document: action.payload, success: true, error: null}
        case 'ERROR':
            return { isPending: false, document: null, success: true, error: action.payload}
    
        default:
            return state
    }
}

export const useFirestore =(collection)=>{
    const [response, dispatch] = useReducer(firestoreReducer, initState)
    const [isCancelled, setIsCancelled] = useState(false)

    //collection ref
    const store = projectFirestore.collection(collection)

    // only Dispatch If Not Cancelled
    const DINC = action =>{
        if(!isCancelled){
            dispatch(action)
        }
    }

    // adding document
    const addDocument = async doc =>{
        dispatch({
            type: 'IS_PENDING'
        })

        try {
            const createdAt = timestamp.fromDate(new Date())
            const addedDoc = await store.add({...doc, createdAt})
            DINC({
                type: 'ADDED_DOC',
                payload: addedDoc
            })
            
        } catch (err) {
            DINC({
                type: 'ERROR',
                payload: err.message
            })
        }
    }

    const updateDocument = async (id, doc) =>{
        dispatch({
            type: 'IS_PENDING'
        })

        try {
            let updatedDoc = await store.doc(id).update(doc)
            DINC({
                type: 'UPDATED_DOC',
                payload: updatedDoc
            })
            
        } catch (err) {
            DINC({
                type: 'ERROR',
                payload: err.message
            })
        }
    }

    const deleteDocument = async id => {
        dispatch({
            type: 'IS_PENDING'
        })

        try {
            
            await store.doc(id).delete()

            DINC({
                type: 'DELETED_DOC'
            })
            
        } catch (err) {
            DINC({
                type: 'ERROR',
                payload: err.message
            })
        }

    }



    useEffect(() => {
        // effect


        return () => setIsCancelled(true)
    }, [])

    return {addDocument, deleteDocument, updateDocument, response}

}