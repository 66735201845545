
export default function MobileDateBtn({ day, date, selectedDate, dispatch, selectedMonth }) {


    // console.log(day)
    const handleClick =()=>{
        // setSelMobileDate(`${selectedDate.split('-')[0]}-${date}-${selectedDate.split('-')[2]}`)
        if(selectedMonth){
            dispatch({
                type: 'SET_ALL_DATE_STATE',
                payload: `${selectedDate.split('-')[0]}-${date}-${selectedDate.split('-')[2]}`
            })
        }
        
        // setSelMobileDateNum(index)
    }
    return (
        <span onClick={handleClick} className={`widget ${selectedDate.split('-')[1] === date ? "active" : ""} ${selectedMonth ? "" : "disabled"} flex-col-center-center`}>
            {day}
            <p>{date}</p>
        </span>
    )
}
