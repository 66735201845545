
import { useEffect, useState } from 'react'
import { getCorrectDateFormat, getDay } from '../../helper/helper'
import TransactionWidgetContainer from './TransactionWidgetContainer'
import { useIsMobile } from '../../hooks/useIsMobile'

export default function TransactionWidget({data, currentDate, closeAllTransactions, uid, documents}) {
    const { isMobile } = useIsMobile()
    const [isDisabled, setIsDisabled] = useState(false)
    const [essentialTransactions, setEssentialTransactions] = useState([])
    const [optionalTransactions, setOptionalTransactions] = useState([])
    const [leisureTransactions, setLeisureTransactions] = useState([])
    const [extrasTransactions, setExtrasTransactions] = useState([])
    
    const [transactionTotal, setTransactionlTotal] = useState(0)




    useEffect(() => {
        setEssentialTransactions([])
        setOptionalTransactions([])
        setLeisureTransactions([])
        setExtrasTransactions([])
        setTransactionlTotal(0)


        let trColl = [];
        documents && documents.map(doc => {
            const docDay = new Date(doc.day.toDate())
            const darrd = data.split('-')
            const selDay = getCorrectDateFormat({
                day: darrd[1],
                month: darrd[0],
                year: darrd[2]
            })

            if(docDay.getTime() === selDay.getTime()){
                trColl.push(doc)
            }

            return null
        })
        
        // console.log('trColl', trColl)
        trColl && trColl.map(tr => {
            if(tr.category === 'essential'){
                setEssentialTransactions(prevState => [...prevState, tr])
            }
            if(tr.category === 'optional'){
                setOptionalTransactions(prevState => [...prevState, tr])
            }
            if(tr.category === 'leisure'){
                setLeisureTransactions(prevState => [...prevState, tr])
            }
            if(tr.category === 'extras'){
                setExtrasTransactions(prevState => [...prevState, tr])
            }
            
            setTransactionlTotal( ps => ps + parseFloat(tr.amount) )
            // console.log('transactionTotal', transactionTotal);

            return null
        })

    }, [documents, data])



    useEffect(() => {
        setIsDisabled( data.split('-')[0] !== currentDate.split('-')[0] )
    }, [data, currentDate])



    return (
        <>
        { !isMobile && 
            <div key={`${data}1`} className={`widget flex-col-center-start ${data.split('-')[0] !== currentDate.split('-')[0] ? "disabled" : ""}`}>
                <span 
                    key={`${data}-span`} 
                    className={`header ${isMobile ? 
                        "flex-col-center-center" 
                        : "flex-row-center-center"}`}
                >
                    {getDay((getCorrectDateFormat({
                        day: data.split('-')[1],
                        month: data.split('-')[0],
                        year: data.split('-')[2]
                    })).getDay(), isMobile )}
                    <p>{data.split('-')[1]}</p>
                </span>
                
                <TransactionWidgetContainer 
                    name="Essentials"
                    type="essential"
                    transactions={essentialTransactions}
                    closeAllTransactions={closeAllTransactions}
                    isDisabled={isDisabled}
                    uid={uid}
                    currentDate={data}
                />
                <TransactionWidgetContainer 
                    name="Optional"
                    type="optional"
                    transactions={optionalTransactions}
                    closeAllTransactions={closeAllTransactions}
                    isDisabled={isDisabled}
                    currentDate={data}
                    uid={uid}
                />
                <TransactionWidgetContainer 
                    name="Leisure"
                    type="leisure"
                    transactions={leisureTransactions}
                    closeAllTransactions={closeAllTransactions}
                    isDisabled={isDisabled}
                    currentDate={data}
                    uid={uid}
                />
                <TransactionWidgetContainer 
                    name="Extras"
                    type="extras"
                    transactions={extrasTransactions}
                    closeAllTransactions={closeAllTransactions}
                    isDisabled={isDisabled}
                    currentDate={data}
                    uid={uid}
                />

                {!isMobile && closeAllTransactions && 
                    <div className="container total closed flex-col-center-start">
                        <span className="title flex-row-center-even">
                            <span>Total</span>
                            <span className="numOfTr">${transactionTotal}</span>
                        </span>
                    </div>
                }
            </div>
        }
        
        
        </>
    )
}
