import { useEffect, useState } from "react"
import { useSwipe } from "../../hooks/useSwipe"
import { rngPassword } from "../../helper/helper"

export default function TransactionMonthlySummarySignificant({ data }) {

    const [activeWidget, setActiveWidget] = useState(0)

    const { swipeDirection, Swiper } = useSwipe()

    const handleNextActiveWidgetClick =()=>{
        if( ( activeWidget + 1 ) >= data.length ){
            setActiveWidget(0)
        }else{
            setActiveWidget(ps => ps + 1)
        }
    }
    const handlePrevActiveWidgetClick =()=>{
        // console.log('prevclick')
        if( ( activeWidget) <= 0 ){
            setActiveWidget(data.length - 1)
        }else{
            setActiveWidget(ps => ps - 1)
        }
    }

    useEffect(() => {
        // console.log(swipeDirection)
        if(swipeDirection){
            if(swipeDirection.direction === "right"){
                handleNextActiveWidgetClick()
            }
            if(swipeDirection.direction === "left"){
                handlePrevActiveWidgetClick()
            }
        }

    }, [swipeDirection])

    return (
        
            <Swiper className="monthly-summary-significant-expenses-con flex-col-center-start">
                {data && <>
                    <span className="title">Significant Expenses</span>
                    <div className="widget flex-row-center-between super">
                        <span>Spending on</span>
                        <p>{data[activeWidget].name}</p>
                    </div>
                    {data[activeWidget].weekly.map((d, index) => 
                        <div key={rngPassword()} className="widget flex-row-center-between">
                            <span>Week {index + 1}</span>
                            <p>${d}</p>
                        </div>
                    )}
                    <div className="widget flex-row-center-between super">
                        <span>Total</span>
                        <p>${data[activeWidget].amount}</p>
                    </div>
                    <div className="footer flex-row-center-center">
                        <img onClick={handlePrevActiveWidgetClick} src="/icons/arrow_left_black_48dp.svg" alt="" />
                        {data.map((d, index) =>
                            <div key={rngPassword()} className={`footwidget ${activeWidget === index ? "active" : ""}`}></div>
                        )}
                        <img onClick={handleNextActiveWidgetClick} src="/icons/arrow_right_black_48dp.svg" alt="" />
                    </div>
                </>}
            </Swiper>
        
    )
}
