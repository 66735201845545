import './Website.css'
import { spline } from '../../helper/helper';
import SimplexNoise from 'simplex-noise'
import { useEffect, useState } from 'react';

export default function Website() {

    
    const [path_d, setPath_d] = useState("")
    
    const [isAnimating, setIsAnimating] = useState(true)
    const simplex = new SimplexNoise()


    


    useEffect(() => {

        // animate()
        // setIsAnimating(true)
        return ()=>{
            // animate(false)
            // setIsAnimating(false)
        }
    }, [])

    useEffect(() => {
        // console.log(isAnimating);
        const root = document.documentElement;
        let hueNoiseOffset = 0;
        const noiseStep = 0.001
        const points = createPoints();

        function animate() {
            if(isAnimating){
                setPath_d(spline(points, 1, true))
            
                // path.setAttribute("d", spline(points, 1, true));

                for (let i = 0; i < points.length; i++) {
                    const point = points[i];

                    // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
                    const nX = simplex.noise2D(point.noiseOffsetX, point.noiseOffsetX);
                    const nY = simplex.noise2D(point.noiseOffsetY, point.noiseOffsetY);
                    // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
                    const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
                    const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);

                    // update the point's current coordinates
                    point.x = x;
                    point.y = y;

                    // progress the point's x, y values through "time"
                    point.noiseOffsetX += noiseStep;
                    point.noiseOffsetY += noiseStep;

                }

                // const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
                const hueNoise = simplex.noise2D(hueNoiseOffset, hueNoiseOffset);

                const hue = map(hueNoise, -1, 1, 0, 360);

                root.style.setProperty("--startColor", `hsl(${hue}, 100%, 75%)`);
                root.style.setProperty("--stopColor", `hsl(${hue + 60}, 100%, 75%)`);
                

                hueNoiseOffset += noiseStep / 6;

                if(isAnimating){
                    // console.log('aaa', isAnimating)
                    requestAnimationFrame(animate);
                }
            }
        }
        animate()
        return () => {
            setIsAnimating(false)
        };
    }, [isAnimating]);
    

    

    function map(n, start1, end1, start2, end2) {
        return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    
    function createPoints() {
        const points = [];
        // how many points do we need
        const numPoints = 6;
        // used to equally space each point around the circle
        const angleStep = (Math.PI * 2) / numPoints;
        // the radius of the circle
        const rad = 75;

        for (let i = 1; i <= numPoints; i++) {
            // x & y coordinates of the current point
            const theta = i * angleStep;

            const x = 100 + Math.cos(theta) * rad;
            const y = 100 + Math.sin(theta) * rad;

            // store the point's position
            points.push({
            x: x,
            y: y,
            // we need to keep a reference to the point's original point for when we modulate the values later
            originX: x,
            originY: y,
            // more on this in a moment!
            noiseOffsetX: Math.random() * 1000,
            noiseOffsetY: Math.random() * 1000
            });
        }

        return points;
    }


   

 
    const addNoise =()=>{
        // setNoiseStep(0.01)
        // console.log('asdasd');
    }
    const resetNoise =()=>{
        // setNoiseStep(0.005)
    }


    return (
        <div className="website-container flex-col-center-start">
            <div className="svgContainer flex-row-center-center">
                {isAnimating && 
                    <svg id="slime_svg" onMouseOver={addNoise} onMouseLeave={resetNoise} viewBox="0 0 200 200">
                        <defs>
                            <linearGradient id="gradient" gradientTransform="rotate(90)">
                                <stop id="gradientStop1" offset="0%" stopColor="var(--startColor)" />
                                <stop id="gradientStop2 " offset="100%" stopColor="var(--stopColor)" />
                            </linearGradient>
                        </defs>
                        {/* <clipPath id="shape"> */}
                            <path d={path_d} fill="url('#gradient')"></path>
                        {/* </clipPath> */}
                        {/* <text x="50%" y="40%" dominant-baseline="middle" text-anchor="middle" color="white">_  _</text> */}
                        {/* <image width="100%" height="100%" clipPath="url(#shape)"  xlinkHref="https://s3-us-west-2.amazonaws.com/s.cdpn.io/222579/beagle400.jpg" >
                        </image> */}
                    </svg>
                }
            </div>
            <br />
                <span>Hello, I am Lezzt Finance or Lezfin for short</span>
            <br />
                <span>I was created as a free Finance Management Service for <a href="https://lezzt.com/" target="_blank" rel="noopener noreferrer">Lezzt</a></span>
            <br />
            
            <span>I'm using different Technologies including: </span>
            <div className="tech flex-col-center-star">

                <div className="widget">
                    <span>React
                        <p>React V17</p>
                    </span>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" alt="" />
                </div>
                <div className="widget">
                    <span className="flex-col-end-center">
                    React 
                    <p>Router V6</p>
                    </span>
                    <img src="https://cdn.freebiesupply.com/logos/thumbs/2x/react-router-logo.png" alt="" />
                </div>
                <div className="widget">
                    <span className="flex-col-end-center">
                    Firebase
                    <p>Firebase v8</p>
                    </span>
                    <img src="https://lh3.googleusercontent.com/h7o8D4bQrA7WWKR5z7BZrCRjyXlGzeaAzbVIysYwjTZQ6yglRQclV7Ngdw6c3hQMchQ2PelXMatD_HrythaDgLr8B9XZ5SKpmlbk" alt="" />
                </div>
                <div className="widget">
                <span className="flex-col-end-center">
                    Google
                    <p>React Charts</p>
                    </span>
                    <img src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA" alt="" />
                </div>
                <div className="widget">
                    <span>Html, Css, JS
                    <p>ES 6</p>
                    </span>
                    <img src="https://www.freepnglogos.com/uploads/html5-logo-png/html5-logo-best-web-design-psd-html-cms-development-ecommerce-6.png" alt="" />
                </div>
                
            </div>
            

            <br />
                <span>Owned and Managed by <a href="https://stlln.com" target="_blank" rel="noopener noreferrer">Noel Santillan</a> &copy;2021 </span>
            <br />

        </div>
    )
}
