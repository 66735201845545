import './Dashboard.css'
import './DashboardMobile.css'
import { useState, useEffect } from 'react'
import { getDateNow, dateToMonthText, dateToText, rngPassword, getDay, getFirstAndLastDateFromWeek, getCorrectDateFormat } from '../../helper/helper'
import SetDatePopup from './SetDatePopup'
import TransactionWidget from './TransactionWidget'
import TransactionWeeklySummary from './TransactionWeeklySummary'
import TransactionMonthlySummary from './TransactionMonthlySummary'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import { useIsMobile } from '../../hooks/useIsMobile'
import MobileDateBtn from './mobile/MobileDateBtn'
import MobileWidgetCardContainer from './mobile/MobileWidgetCardContainer'
import { useSwipe } from '../../hooks/useSwipe'
import { useToast } from '../../hooks/useToast'
import MobileTransactionWeeklyReport from './mobile/MobileTransactionWeeklyReport'

export default function NewDashboard() {

    const { user, selectedDate, selectedMonthObj, selectedMonth, selectedYear, selectedWeek, dispatch } = useAuthContext()
    const { isMobile } = useIsMobile()
    const { showToast, toast } = useToast(2000)

    
    const { documents } = useCollection(
        'transactions', 
        [
            ["uid", "==", user.uid],
        ],
        ["day", "desc"]
    )
    const { documents: userdata } = useCollection('userdata', [
        ["uid", "==", user.uid],
        ["month", "==", selectedDate.split('-')[0]],
        ["year", "==", selectedDate.split('-')[2]]
    ])

    
    const { swipeDirection, Swiper } = useSwipe() 
    const { swipeDirection: navSwipeDirection, Swiper: NavSwipe } = useSwipe() 


    // console.log(selectedMonth)
    // console.log(selectedDate, selectedMonthObj, selectedMonth, selectedYear, selectedWeek)

    // const [selectedWeek, setMySetselectedWeek] = useState(selectedWeek);
    const [thisWeek, setThisWeek] = useState(0)
    
    const [isSelectingDate, setIsSelectingDate] = useState(false)
    const [closeAllTransactions, setCloseAllTransactions] = useState(true);
    const [showMenuBook, setshowMenuBook] = useState(false)

    
    const [weeklyTotal, setWeeklyTotal] = useState(0)
    const [totalIncome, setTotalIncome] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)
    const [forecastSave, setForecastSave] = useState(0)
    const [totalMonthlyExpense, setTotalMonthlyExpense] = useState(0)
    
    

    
    const [useFirstTime, setUseFirstTime] = useState(true)

    useEffect(() => {
        setTotalIncome(0)
        setTotalExpense(0)

        if(userdata){
            if(userdata[0]){
                userdata[0].monthlyIncome.map(d => setTotalIncome(ps => ps + parseFloat(d.amount)))
                userdata[0].monthlyExpense.map(d => setTotalExpense(ps => ps + parseFloat(d.amount)))
                setForecastSave(userdata[0].goal.forecastSavings)
            }
        }

    }, [userdata]);

    // useEffect(() => {
    //     setWeeklyTotal(0)
    //     setTotalMonthlyExpense(0)
    //     documents && documents.forEach(doc => {
    //         const sd = new Date(selectedMonthObj[selectedWeek][0])
    //         const ed = new Date(selectedMonthObj[selectedWeek][6])
    //         const sd1 = new Date(selectedMonthObj[0][0])
    //         const ed1 = new Date( selectedMonthObj[selectedMonthObj.length -1][6] )
    //         const docDay = new Date(doc.day.toDate())
    //         if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime()){
    //             setWeeklyTotal(ps => ps + parseFloat(doc.amount))
    //         }
    //         if(docDay.getTime() >= sd1.getTime() && docDay.getTime() <= ed1.getTime()){
    //             setTotalMonthlyExpense(ps => ps + parseFloat(doc.amount))
    //         }
    //     })

    // }, [documents, selectedMonthObj, selectedWeek])

    useEffect(() => {
        setWeeklyTotal(0)
        setTotalMonthlyExpense(0)
        documents && documents.forEach(doc => {
            const sddarr = selectedMonthObj[selectedWeek][0].split('-')
            const eddarr = selectedMonthObj[selectedWeek][6].split('-')
            const sd1darr = selectedMonthObj[0][0].split('-')
            const ed1darr = selectedMonthObj[selectedMonthObj.length -1][6].split('-')

            const sd = getCorrectDateFormat({
                day: sddarr[1],
                month: sddarr[0],
                year: sddarr[2]
            })
            const ed = getCorrectDateFormat({
                day: eddarr[1],
                month: eddarr[0],
                year: eddarr[2]
            })
            const sd1 = getCorrectDateFormat({
                day: sd1darr[1],
                month: sd1darr[0],
                year: sd1darr[2]
            })
            const ed1 = getCorrectDateFormat({
                day: ed1darr[1],
                month: ed1darr[0],
                year: ed1darr[2]
            })

            const docDay = new Date(doc.day.toDate())
            if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime()){
                setWeeklyTotal(ps => ps + parseFloat(doc.amount))
            }
            if(docDay.getTime() >= sd1.getTime() && docDay.getTime() <= ed1.getTime()){
                setTotalMonthlyExpense(ps => ps + parseFloat(doc.amount))
            }
        })

    }, [documents, selectedMonthObj, selectedWeek])
    
    useEffect(() => {
        // console.log('UPDATE MOBILE STATE')
        if(selectedMonthObj && !isMobile){
            // console.log('AAAA', selectedMonthObj[0])
            selectedMonthObj.forEach((data, index) => {
                data.forEach(d => {
                    const darr = d.split('-')
                    // const d2darr = `${getDateNow().month}-${getDateNow().day}-${getDateNow().year}`.split('-')
                    const d1 = dateToText(getCorrectDateFormat({
                        day: darr[1],
                        month: darr[0],
                        year: darr[2]
                    }))
                    const d2 = dateToText(getCorrectDateFormat({
                        day: getDateNow().day,
                        month: getDateNow().month,
                        year: getDateNow().year
                    }))
                    if(d1 === d2){
                        if(useFirstTime){
                            dispatch({
                                type: "SET_WEEK",
                                payload: index
                            })
                            setUseFirstTime(false)
                        }
                        
                        setThisWeek(index)
                    }
                })
            })
        }
        // if(selectedMonthObj && isMobile){
        //     // console.log('UPDATE MOBILE STATE')

        // }
    }, [selectedMonthObj, isMobile, dispatch, useFirstTime]);

    const handlePrevWeekClick =()=> {
        if(selectedWeek <= 0){
            // console.log('do not proceed')
        }else{
            // console.log('proceed');
            // setMySetselectedWeek(ps => ps - 1)
            if(isMobile){
                // console.log(getFirstAndLastDateFromWeek(selectedWeek - 1, selectedMonth, selectedYear));
                // console.log(thisWeek, selectedWeek + 1)
                if(selectedWeek - 1 === thisWeek && selectedMonth === getDateNow().month){
                    dispatch({
                        type: 'SET_DATE',
                        payload: `${getDateNow().month}-${getDateNow().day}-${getDateNow().year}`
                    })
                }else{
                    dispatch({
                        type: 'SET_DATE',
                        payload: getFirstAndLastDateFromWeek(selectedWeek - 1, selectedMonth, selectedYear).first
                    })
                }

                
            }
            dispatch({
                type: "SET_WEEK",
                payload: selectedWeek - 1
            })
        }
        setshowMenuBook(false)
    }
    
    const handleNextWeekClick =()=> {
        // console.log(selectedWeek, selectedMonthObj.length - 1);
        
        if(selectedWeek >= selectedMonthObj.length - 1){
            // console.log('do not add')
        }else{
            // console.log('add');
            // setMySetselectedWeek(ps => ps + 1)
            
            if(isMobile){
                // console.log(getFirstAndLastDateOfMonth(selectedMonth, selectedYear));
                if(selectedWeek + 1 === thisWeek && selectedMonth === getDateNow().month){
                    dispatch({
                        type: 'SET_DATE',
                        payload: `${getDateNow().month}-${getDateNow().day}-${getDateNow().year}`
                    })
                }else{
                    dispatch({
                        type: 'SET_DATE',
                        payload: getFirstAndLastDateFromWeek(selectedWeek + 1, selectedMonth, selectedYear).last
                    })
                }
                
            }
            dispatch({
                type: "SET_WEEK",
                payload: selectedWeek + 1
            })
        }
        setshowMenuBook(false)
    }

    const handleResetToNowClick =()=>{
        // setCurrentDate(`${getDateNow().month}-${getDateNow().day}-${getDateNow().year}`)
        // console.log(`${getDateNow().month}-${getDateNow().day}-${getDateNow().year}`)
        dispatch({
            type: 'SET_ALL_DATE_STATE',
            payload: `${getDateNow().month}-${getDateNow().day}-${getDateNow().year}`
        })
        // setMySetselectedWeek(thisWeek)
        dispatch({
            type: "SET_WEEK",
            payload: thisWeek
        })
        setshowMenuBook(false)

        // setSelMobileDate(`${getDateNow().month}-${getDateNow().day}-${getDateNow().year}`)
    }


    const mobileSetDateFromSwipe =(t)=>{
        // const sd = new Date(selectedMonthObj[selectedWeek][0])
        // const ed = new Date(selectedMonthObj[selectedWeek][6])
        const d = getFirstAndLastDateFromWeek(selectedWeek, selectedMonth, selectedYear)

        // console.log(d)
        const darr1 = d.first.split('-')
        const darr2 = d.last.split('-')

        const sd = getCorrectDateFormat({
            day: darr1[1],
            month: darr1[0],
            year: darr1[2]
        })
        const ed = getCorrectDateFormat({
            day: darr2[1],
            month: darr2[0],
            year: darr2[2]
        })

        
        const darrsel = selectedDate.split('-')

        const nextDate = getCorrectDateFormat({
            day: darrsel[1],
            month: darrsel[0],
            year: darrsel[2]
        })

        if(t === 'right'){
            nextDate.setDate(nextDate.getDate() + 1)
        }else if(t === 'left'){
            nextDate.setDate(nextDate.getDate() - 1)
        }



        if(nextDate.getTime() > ed.getTime() || nextDate.getTime() < sd.getTime() ){
            // console.log('bump! date will exceed bounds');
            if(nextDate.getTime() > ed.getTime()){
                // console.log('going next week', selectedWeek, selectedMonthObj.length)
                if((selectedWeek + 2) > selectedMonthObj.length){
                    // console.log('this is the last week');
                    showToast({message: 'date will exceed bounds, please move to next month'});
                    return;
                }else{
                    // setselectedWeek(ps => ps + 1)
                    dispatch({
                        type: "SET_WEEK",
                        payload: selectedWeek + 1
                    })
                    
                    const darrsel = selectedDate.split('-')

                    const nd = getCorrectDateFormat({
                        day: darrsel[1],
                        month: darrsel[0],
                        year: darrsel[2]
                    })

                    nd.setDate(nd.getDate() + 1)
                    // setselectedDate(dateToText(nd))
                    dispatch({
                        type: "SET_DATE",
                        payload: dateToText(nd)
                    })
                }

                
            }
            if(nextDate.getTime() < sd.getTime()){
                // console.log('going a week before', selectedWeek, selectedMonthObj.length)
                if(selectedWeek <= 0){
                    // console.log('this is the last week');
                    showToast({message: 'date will exceed bounds, please move to the month before'});
                }else{
                    // setselectedWeek(ps => ps - 1)
                    dispatch({
                        type: "SET_WEEK",
                        payload: selectedWeek - 1
                    })
                    const darrsel = selectedDate.split('-')
                    const nd = getCorrectDateFormat({
                        day: darrsel[1],
                        month: darrsel[0],
                        year: darrsel[2]
                    })
                    nd.setDate(nd.getDate() - 1)
                    // setSelMobileDate(dateToText(nd))
                    dispatch({
                        type: "SET_DATE",
                        payload: dateToText(nd)
                    })
                }

            }
            return
        }else{
            // setSelMobileDate(dateToText(nextDate))
            dispatch({
                type: "SET_DATE",
                payload: dateToText(nextDate)
            })
        }
    }



    useEffect(() => {
        // console.log(swipeDirection.direction)
        if(swipeDirection){
            if(swipeDirection.direction){
                // console.log(swipeDirection.direction)
                mobileSetDateFromSwipe(swipeDirection.direction)
            }
        }

    }, [swipeDirection]);

    useEffect(() => {
        if(navSwipeDirection){
            if(navSwipeDirection.direction){
                // console.log(navSwipeDirection.direction)
                if(navSwipeDirection.direction === 'right'){
                    handleNextWeekClick()
                }else if(navSwipeDirection.direction === 'left'){
                    handlePrevWeekClick()
                }
            }
        }

    }, [navSwipeDirection]);


    

    

   
    


    return (
        <div className="dashboard-container" >
            {toast}
            <NavSwipe className="dashboard-container-header flex-row-center-between "  >
                <img onClick={handlePrevWeekClick} src="/icons/arrow_left_white_48dp.svg" alt="" />
                <div className="title-con flex-row-center-between">
                    <img onClick={()=> setshowMenuBook(!showMenuBook)} src="/icons/menu_book_white_48dp.svg" alt="Menu Book" />
                    <br />
                    <span 
                        onClick={()=>setIsSelectingDate(!isSelectingDate)} 
                        className="month flex-row-center-center"
                    >
                        { <span>{dateToMonthText(selectedDate, isMobile)}</span>}
                        {<p>{isMobile ? 
                            selectedDate.split('-').pop()
                            : selectedDate.split('-').pop()
                        }</p>} 
                        &nbsp; 
                        &nbsp; 
                        {isMobile ? `w${selectedWeek + 1}` : `Week ${selectedWeek + 1}`}
                    </span>
                    
                    <span className="frag flex-row-center-between">
                        {parseFloat(thisWeek) === parseFloat(selectedWeek) && selectedMonth === getDateNow().month ? <p className="idle">&bull;</p> : 
                            <p 
                                onClick={handleResetToNowClick} 
                                title="Click me to go to current week!" 
                                className="return red"
                            >
                            &bull;</p>
                        }
                        {closeAllTransactions ? 
                            <img 
                                onClick={()=> setCloseAllTransactions(false)} 
                                title="Click to Show all Transaction" src="/icons/visibility_white_48dp.svg" 
                                alt=""
                            /> : 
                            <img 
                                onClick={()=> setCloseAllTransactions(true)} 
                                title="Click to Hide all Transaction" 
                                src="/icons/visibility_off_white_48dp.svg" 
                                alt=""
                            /> }
                    </span>
                    {isSelectingDate && 
                        <SetDatePopup 
                            // selectedMonthObj={selectedMonthObj}
                            // setCurrentWeek={setCurrentWeek}
                            setIsSelectingDate={setIsSelectingDate}
                            // selectedDate={selectedDate}
                            // dispatch={dispatch}
                            setshowMenuBook={setshowMenuBook}
                            // setMySetselectedWeek={setMySetselectedWeek}
                            // setSelMobileDate={setSelMobileDate}
                        />
                    }
                    
                </div>
                <img onClick={handleNextWeekClick} src="/icons/arrow_right_white_48dp.svg" alt="" />
            </NavSwipe>

            {!isMobile &&
                <>
                    <div className="dashboard-container-content flex-row-start-between">
                        {
                            documents && !showMenuBook && selectedMonthObj && (selectedWeek !== null) && !isSelectingDate ? selectedMonthObj[selectedWeek].map((d) => 

                                <TransactionWidget
                                    data={d}
                                    currentDate={selectedDate}
                                    key={d}
                                    closeAllTransactions={closeAllTransactions}
                                    uid={user.uid}
                                    documents={documents}
                                />
                            
                            ) : null
                        }
                        

                    </div>
                
                    {   documents && !isSelectingDate && !showMenuBook && closeAllTransactions &&
                        <div className="dashboard-container-content flex-row-start-between">
                            <TransactionWeeklySummary 
                                documents={documents}
                                currentMonthObj={selectedMonthObj}
                                currentWeek={selectedWeek}
                                type="essential"
                            />
                            <TransactionWeeklySummary 
                                documents={documents}
                                currentMonthObj={selectedMonthObj}
                                currentWeek={selectedWeek}
                                type="optional"
                            />
                            <TransactionWeeklySummary 
                                documents={documents}
                                currentMonthObj={selectedMonthObj}
                                currentWeek={selectedWeek}
                                type="leisure"
                            />
                            <TransactionWeeklySummary 
                                documents={documents}
                                currentMonthObj={selectedMonthObj}
                                currentWeek={selectedWeek}
                                type="extras"
                            />
                        </div>
                    }
                    {   documents && !isSelectingDate && !showMenuBook && closeAllTransactions && 
                        <div className="dashboard-container-content flex-row-start-between">
                            <div className="weekly-total flex-row-center-even">
                                <div>
                                    <span>Weekly Total: </span>
                                    <span>${weeklyTotal}</span>
                                </div>
                                <div>
                                    <span>Remaining Budget: </span>
                                    <span>${totalIncome - totalExpense - forecastSave - totalMonthlyExpense}</span>
                                </div>
                            </div>
                        </div>
                    }
                    
                    {/* {showMenuBook && selectedMonthObj && 
                        <TransactionMonthlySummary 
                            key={rngPassword()}
                            documents={documents}
                        />
                    } */}
                </>
            }

            {isMobile && documents && selectedMonthObj && !isSelectingDate && !showMenuBook && 
                <>
                <Swiper className="dashboard-container-content flex-col-center-start">
                    <div className="mobile-date-btn-con flex-row-center-between">
                        {
                            documents && !showMenuBook && selectedMonthObj && (selectedWeek !== null) && !isSelectingDate ? selectedMonthObj[selectedWeek].map((d, i) => 

                                <MobileDateBtn 
                                    day={getDay(getCorrectDateFormat({
                                        day: d.split('-')[1],
                                        month: d.split('-')[0],
                                        year: d.split('-')[2]
                                    }).getDay())}
                                    date={d.split('-')[1]}
                                    selectedDate={selectedDate}
                                    dispatch={dispatch}
                                    index={i}
                                    key={`${d}-jukes`}
                                    selectedMonth={parseFloat(selectedMonth) === parseFloat(d.split('-')[0])}
                                />
                            
                            ) : null
                        }
                    </div>
                    <MobileWidgetCardContainer 
                        closeAllTransactions={closeAllTransactions}
                        documents={documents}
                        selectedDate={selectedDate}
                        dispatch={dispatch}
                    />
                    
                    

                    

                </Swiper>
                <br />  
                <div className="dashboard-container-content flex-col-center-start">
                    <MobileTransactionWeeklyReport 
                        documents={documents}
                        // selectedMonthObj={selectedMonthObj}
                        // selectedWeek={selectedWeek}
                    />
                </div>
                
                {   documents && !isSelectingDate && !showMenuBook && closeAllTransactions && 
                    <div className="dashboard-container-content flex-row-center-center">
                        <div className="weekly-total flex-row-center-between">
                            <div className="flex-col-start-center">
                                <span>Weekly Total: </span>
                                <span>${weeklyTotal}</span>
                            </div>
                            <div className="flex-col-end-center">
                                <span>Remaining Budget: </span>
                                <span>${totalIncome - totalExpense - forecastSave - totalMonthlyExpense}</span>
                            </div>
                        </div>
                    </div>
                }
                
                
                </>
            }

            
            {showMenuBook && 
                <>
                <TransactionMonthlySummary 
                    key={rngPassword()}
                    documents={documents}
                />
                </>
            }


        
        </div>
    )
}
