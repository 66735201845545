import { useEffect, useState } from "react";
import CustomForm from "../../helper/CustomForm";
import { rngPassword } from "../../helper/helper";
import TransactionMonthlySummaryListWidget from "./TransactionMonthlySummaryListWidget";
import { useFirestore } from "../../hooks/useFirestore"
import { useIsMobile } from "../../hooks/useIsMobile";


export default function TransactionMonthlySummaryList({ title, type, data, id }) {
    const [addMonthlyIncomeName, setAddMonthlyIncomeName] = useState('')
    const [addMonthlyIncomeAmount, setAddMonthlyIncomeAmount] = useState('')
    const { updateDocument, response } = useFirestore('userdata')
    const { isMobile } = useIsMobile()

    const [total, setTotal] = useState(0)

    const [showMobileModal, setShowMobileModal] = useState(false);



    // console.log('data', id);
    
    const handleDeleteClick =(index, did)=>{

        const newData = []
        data.map(d => {
            if(d.id !== did){
                newData.push(d)
            }
            return null
        })
        console.log(id, newData)

        updateDocument(id,
            {
                [type]: [...newData]
            }
        )

    }
    const handleSubmit =e=>{
        e.preventDefault();

        updateDocument(id,
            {
                [type]: [...data,
                    {
                        id: rngPassword(),
                        name: addMonthlyIncomeName, 
                        amount: addMonthlyIncomeAmount
                    }
                ]
            }
        )

    }


    useEffect(() => {

        if(data){
            setTotal(0)
            if(data.length){
                data.map(d => {
                    setTotal(ps => ps + parseFloat(d.amount))

                    return null
                })
            }

        }
        
    }, [data]);

    useEffect(() => {
        // console.log(response)
        if(response.success){
            setAddMonthlyIncomeName('')
            setAddMonthlyIncomeAmount('')
        }
    }, [response.success]);

    return (
        <div className={`monthly-summary-list-con ${isMobile ? "flex-row-center-center" : "flex-row-start-between"}`} >
            <div className="left-con flex-col-center-start">
                <span className={`title ${isMobile ? "flex-row-center-between" : ""}`}>{title} {isMobile && <img onClick={()=> setShowMobileModal(true)} src="/icons/add_circle_white_48dp.svg" alt="" /> } </span>
                <div className="list-con flex-col-center-start">
                    {data && data.map((data, index) => 
                        <TransactionMonthlySummaryListWidget 
                            handleDeleteClick={()=>handleDeleteClick(index, data.id)}
                            data={data}
                            key={data.id}
                        />
                    )}
                    {data.length > 0 ? 
                        <div className="widget total flex-row-center-between">
                            <span className="title">Total</span>
                            <span className="amount flex-row-center-between">${total}</span>
                        </div>
                        : null
                    }
                </div>
            </div>
            {isMobile && showMobileModal && 
                <div className="right-con">
                    <CustomForm
                        handleSubmit={handleSubmit}
                        title={<>{`Add ${title}`} <img onClick={()=> setShowMobileModal(false)} src="/icons/close_white_48dp.svg" alt="" /></>}
                        className="form-control"
                    >
                        <div className="field">
                            <span>Name</span>
                            <input type="text" onChange={e => {setAddMonthlyIncomeName(e.target.value)}} value={addMonthlyIncomeName} required/>
                        </div>
                        <div className="field">
                            <span>Amount</span>
                            <input type="number" min="0" onChange={e => {setAddMonthlyIncomeAmount(e.target.value)}} value={addMonthlyIncomeAmount} required/>
                        </div>
                        <button>Add</button>

                    </CustomForm>
                </div>
            }
            {!isMobile &&
                <div className="right-con">
                    <CustomForm
                        handleSubmit={handleSubmit}
                        title={<>{`Add ${title}`} {isMobile && <img onClick={()=> setShowMobileModal(false)} src="/icons/close_white_48dp.svg" alt="" />} </>}
                        className="form-control"
                    >
                        <div className="field">
                            <span>Name</span>
                            <input type="text" onChange={e => {setAddMonthlyIncomeName(e.target.value)}} value={addMonthlyIncomeName} required/>
                        </div>
                        <div className="field">
                            <span>Amount</span>
                            <input type="number" min="0" onChange={e => {setAddMonthlyIncomeAmount(e.target.value)}} value={addMonthlyIncomeAmount} required/>
                        </div>
                        <button>Add</button>

                    </CustomForm>
                </div>
            }
        </div>
    )
}
