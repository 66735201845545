import { useState, useEffect } from "react"
import TransactionWidgetContent from './TransactionWidgetContent'
import { rngPassword, getSubCategory, getCorrectDateFormat } from '../../helper/helper'
import Modal from '../../helper/Modal'
import { useFirestore } from "../../hooks/useFirestore"
import { timestamp } from "../../firebase/config"


export default function TransactionWidgetContainer({name, type, transactions, closeAllTransactions, isDisabled, uid, currentDate, selectedDay}) {
    const { addDocument, response } = useFirestore('transactions')

    const [isClosed, setIsClosed] = useState(closeAllTransactions)
    const [showModal, setShowModal] = useState(false);
    const [subCatList, setSubCatList] = useState(null);

    const [selSubCat, setSelSubCat] = useState("na");
    const [selTitle, setSelTitle] = useState("");
    const [selAmount, setSelAmount] = useState("");

    
    

    useEffect(() => {
        
        setIsClosed(closeAllTransactions)
    }, [closeAllTransactions]);

    useEffect(() => {
        setSubCatList(getSubCategory(type))

    }, [type]);

    


    const handleSubmit =e=>{
        e.preventDefault();
        addDocument({
            uid,
            category: type,
            subcategory: selSubCat,
            name: selTitle,
            amount: selAmount,
            day: timestamp.fromDate(getCorrectDateFormat({
                day: currentDate.split('-')[1],
                month: currentDate.split('-')[0],
                year: currentDate.split('-')[2]
            }))
        })
    }
    const handleTransactionCloseClick =()=>{
        if(!isDisabled){
            setIsClosed(!isClosed) 
        }
    }

    useEffect(() => {
        if(!showModal){
            setSelSubCat("na")
            setSelTitle('')
            setSelAmount('')
        }
    }, [showModal])

    useEffect(() => {
        // effect
        
        if(response.success){
            setSelSubCat('na')
            setSelTitle('')
            setSelAmount('')
            setShowModal(false)
        }
    }, [response.success]);

    return (
        <>
            {showModal && 
                <Modal 
                    closeModal={()=> setShowModal(false)}
                >
                    <h3>Add Transaction to {subCatList.title}</h3>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <span>Choose Sub Category</span>
                            <select onChange={e=> setSelSubCat(e.target.value)} value={selSubCat} required>
                                <option value="na" disabled>Please Select One</option>
                                {subCatList && subCatList.data.map(sc => <option key={sc.id} value={sc.id}>{sc.name}</option> )}
                            </select>
                        </div>
                        {subCatList && subCatList.data.map(sc =>  <p>{sc.id === selSubCat ? sc.description : ""}</p>  )}
                        <div className="field">
                            <span>Title</span>
                            <input value={selTitle} onChange={e=> setSelTitle(e.target.value)} type="text" required />
                        </div>
                        <div className="field">
                            <span>Amount</span>
                            <input value={selAmount} onChange={e=> setSelAmount(e.target.value)} type="number" required/>
                        </div>
                        <button>Save</button>
                    </form>
                </Modal>
            }

            <div key={rngPassword()} className={`container ${type} ${isClosed ? "closed" : ""} flex-col-center-start`}>
                <span 
                    className={`title flex-row-center-even `}
                >
                    <span 
                        onClick={handleTransactionCloseClick}>
                        {name}
                    </span> 
                    {isClosed && <span 
                        className="numOfTr">{transactions.length}
                    </span> } 
                    {!isClosed && !isDisabled && 
                        <img 
                            onClick={()=> setShowModal(true)} 
                            src="/icons/add_circle_white_48dp.svg" 
                            alt="add" 
                        />
                    } 
                </span>
                
                
                {!isDisabled && 
                    <TransactionWidgetContent
                        transactions={transactions}
                        isClosed={isClosed}
                        id={rngPassword()}
                        key={rngPassword()}
                    />
                }
            </div>

            
        </>
    )
}
