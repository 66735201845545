import { useState, useEffect } from 'react'
import { getCorrectDateFormat, getDateNow, getFirstAndLastDateFromWeek } from '../../helper/helper'
import { getMonthObject, dateToText } from '../../helper/helper';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useAuthContext } from '../../hooks/useAuthContext';

export default function SetDatePopup({ setIsSelectingDate, setshowMenuBook }) {

    
    const { selectedDate, selectedMonthObj, dispatch } = useAuthContext()

    const { isMobile } = useIsMobile()
    const [selectedPopupDate, setSelectedPopupDate] = useState('')
    const [myMonthObj, setMyMonthObj] = useState(selectedMonthObj);
    const popupMaxDate = `${getDateNow().year}-${getDateNow().month}`
    const popupMinDate = "2021-01"



    // console.log(myMonthObj);
    
    const handlePopupClick =(i)=>{
        setIsSelectingDate(false)
        const darr = selectedPopupDate.split('-');
        
        // console.log('AA',i)
        dispatch({
            type: 'SET_WEEK',
            payload: i
        })
        // setMySetselectedWeek(i)

        
        if(isMobile){
            console.log('ON MOBILE');

            // setMyMonthObj(getMonthObject(dateToText(`${e.target.value.split('-')[1]}-15-${e.target.value.split('-')[0]}`)))
            // if(selectedMonthObj){
            //     // selectedMonthObj.forEach((data, index) => {
            //     //     data.forEach(d => {
            //     //         const d1 = dateToText(new Date(d))
            //     //         const d2 = dateToText(new Date(`${getDateNow().month}-${getDateNow().day}-${getDateNow().year}`))
            //     //         if(d1 === d2){
                            
            //     //         }
            //     //     })
            //     // })
            //     const 

            
                // console.log('AAA', getFirstAndLastDateFromWeek(i, darr[1], darr[0]))
                // setMyMonthObj(getMonthObject(dateToText(`${selectedPopupDate.split('-')[1]}-15-${selectedPopupDate.split('-')[0]}`)))
                // console.log(myMonthObj[i])
            dispatch({
                type: 'SET_ALL_DATE_STATE',
                payload: getFirstAndLastDateFromWeek(i, darr[1], darr[0]).first
            })
            // }
            
        }else{
            console.log('ON DESKTOP')
            dispatch({
                type: 'SET_ALL_DATE_STATE',
                payload: `${darr[1]}-15-${darr[0]}`
            })
        }
        setshowMenuBook(false)
    }
    const handlePopupChange =e=>{
        setSelectedPopupDate(e.target.value)
        setMyMonthObj(getMonthObject(dateToText(getCorrectDateFormat({
            day: 15,
            month: e.target.value.split('-')[1],
            year: e.target.value.split('-')[0]
        }))))

        // `${e.target.value.split('-')[1]}-15-${e.target.value.split('-')[0]}`
    }

    
    useEffect(() => {
        const darr = selectedDate.split('-');
        setSelectedPopupDate(`${darr[2]}-${darr[0]}`);
        
    }, [selectedDate]);

    return (
        <div className="popup">
            <input type="month" onChange={handlePopupChange} value={selectedPopupDate} min={popupMinDate} max={popupMaxDate}/>
            { 
                myMonthObj && myMonthObj.map((o, i)=>{
                    return <button onClick={()=> handlePopupClick(i)} key={i}>Week {i + 1}</button>
                })
             }
        </div>
    )
}
