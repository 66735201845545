import { useEffect, useState } from "react"
import TransactionMonthlySummaryList from "./TransactionMonthlySummaryList"
import { Chart } from 'react-google-charts'
import { getSubCategory, getFirstAndLastDateFromWeek, getCategoryChartColors, getCategories, getFirstAndLastDateOfMonth, getCorrectDateFormat } from "../../helper/helper"
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import { useFirestore } from "../../hooks/useFirestore"
import { useIsMobile } from "../../hooks/useIsMobile"
import TransactionMonthlySummarySignificant from "./TransactionMonthlySummarySignificant"

export default function TransactionMonthlySummary({ documents: transactions }) {
    const { user, selectedDate, selectedMonth, selectedYear, selectedMonthObj, authIsReady, selectedWeek } = useAuthContext()
    const { addDocument, updateDocument } = useFirestore('userdata')
    const { isMobile } = useIsMobile()

    const { documents, error } = useCollection('userdata', [
        ["uid", "==", user.uid],
        ["month", "==", selectedDate.split('-')[0]],
        ["year", "==", selectedDate.split('-')[2]],
    ])

    const [isSummaryOpen, setisSummaryOpen] = useState(false)

    const [goalPlan, setGoalPlan] = useState('')
    const [goalReach, setGoalReach] = useState('')
    const [goalForecastSave, setGoalForecastSave] = useState(100)

    const [totalIncome, setTotalIncome] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)

    const [isGoalChanged, setisGoalChanged] = useState(false)

    const [goalNotHere, setGoalNotHere] = useState(false);

    const saveGoal = ()=>{

        updateDocument(documents[0].id, {
            goal: {
                goalPlan: goalPlan,
                goalReach: goalReach,
                forecastSavings: parseFloat(goalForecastSave)
            }
        })
        setisGoalChanged(false)
    }
    
   function addBlankGoal(){
        addDocument({
            monthlyIncome: [],
            monthlyExpense: [],
            goal: {
                goalPlan: '',
                goalReach: '',
                forecastSavings: 0
            },
            uid : user.uid,
            month: selectedDate.split('-')[0],
            year: selectedDate.split('-')[2]
        })
   }

    useEffect(() => {
        // fetch data --> !data ? addDocument
        // console.log('documents', documents)
        if(documents){
            setTotalIncome(0)
            setTotalExpense(0)
            if(!documents.length){
                console.log('not here');
                setGoalNotHere(true)
                
            }else{
                setGoalNotHere(false)
                setGoalPlan(documents[0].goal.goalPlan)
                setGoalReach(documents[0].goal.goalReach)
                setGoalForecastSave(documents[0].goal.forecastSavings)
                setisGoalChanged(false)
                documents[0].monthlyIncome.map(mi => {
                    setTotalIncome(ps => ps + parseFloat(mi.amount))
                    return null
                })
                documents[0].monthlyExpense.map(me => {
                    setTotalExpense(ps => ps + parseFloat(me.amount))
                    return null
                })
            }

        }
        
    }, [documents])

    useEffect(() => {

        setisGoalChanged(true)
    }, [goalPlan, goalReach, goalForecastSave])

    useEffect(() => {

        setisGoalChanged(false)
    }, [])


    function getChartDataPerType(type){
        const headerArr = [
            ['Summary'],
        ]

        selectedMonthObj && selectedMonthObj.forEach((mo, index) => {
            headerArr[0].push(`Week ${index + 1}`)
        })
        
        const subCat = getSubCategory(type)
        let meiruko = []

        subCat.data.forEach(cat => {
            let newArr = [cat.name]
            selectedMonthObj && selectedMonthObj.forEach((mo, index) => {
                

                const fald = getFirstAndLastDateFromWeek(index, selectedMonth, selectedYear)
                let newNum = 0
                transactions && transactions.forEach(transaction => {
                    const darrf = fald.first.split('-')
                    const darrl = fald.last.split('-')

                    const trDay = new Date(transaction.day.toDate())
                    const fd = getCorrectDateFormat({
                        day: darrf[1],
                        month: darrf[0],
                        year: darrf[2]
                    })
                    const ld = getCorrectDateFormat({
                        day: darrl[1],
                        month: darrl[0],
                        year: darrl[2]
                    })
                    // console.log(dateToText(trDay), dateToText(fd), dateToText(ld), (trDay.getTime() <= ld.getTime() && trDay.getTime() >= fd.getTime()))
                    // console.log('AA', trDay, selectedMonth, selectedYear, type)
                    // if(parseInt(trDay.split('-')[0]) === parseInt(selectedMonth) && parseInt(trDay.split('-')[2]) === parseInt(selectedYear) && transaction.category === type){
                    //     // trColl.push( transaction )
                        
                    // }
                    if(trDay.getTime() <= ld.getTime() && trDay.getTime() >= fd.getTime() && transaction.category === type  && transaction.subcategory === cat.id ){
                        newNum += parseFloat(transaction.amount)
                    }
                })
                newArr.push(newNum)
            })
            meiruko.push(newArr)
        })

        return [...headerArr, ...meiruko]
    }
    function getMainChartData(){
        const categories = getCategories();
        const headerArr = [
            ['Summary'],
        ]
        let meiruko = []

        // const dummyData = [
        //     ['Summary', 'Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        //     ['Essential Expense', 235, 126, 742, 111, 52],
        //     ['Optional Expense', 2, 67, 24, 51, 66],
        //     ['Entertainment and Leisure', 252, 235, 678, 63, 732],
        //     ['Extras and Unforseen Events', 512, 32, 5, 1, 66]
        // ]

        selectedMonthObj && selectedMonthObj.forEach((mo, index) => {
            headerArr[0].push(`Week ${index + 1}`)
        })

        categories.forEach(category => {
            let newArr = [category.title]
            selectedMonthObj && selectedMonthObj.forEach((mo, index) => {
                const fald = getFirstAndLastDateFromWeek(index, selectedMonth, selectedYear)
                let newNum = 0
                transactions && transactions.forEach(transaction => {
                    // const fd = new Date(fald.first)
                    // const ld = new Date(fald.last)

                    const darrf = fald.first.split('-')
                    const darrl = fald.last.split('-')

                    const trDay = new Date(transaction.day.toDate())
                    const fd = getCorrectDateFormat({
                        day: darrf[1],
                        month: darrf[0],
                        year: darrf[2]
                    })
                    const ld = getCorrectDateFormat({
                        day: darrl[1],
                        month: darrl[0],
                        year: darrl[2]
                    })
                    if(trDay.getTime() <= ld.getTime() && trDay.getTime() >= fd.getTime() && transaction.category === category.id ){
                        newNum += parseFloat(transaction.amount)
                    }         
                })
                newArr.push(newNum)
            })
            meiruko.push(newArr)
        })

        // console.log('PEK',[...headerArr, ...meiruko])
        // return dummyData
        return [...headerArr, ...meiruko]
    }
    function getSignificantExpenses(){
            let meiruko = [];
            const fald = getFirstAndLastDateOfMonth(selectedMonth, selectedYear)
            transactions && transactions.forEach((transaction) => {
                // console.log(meiruko);
                const trDay = new Date(transaction.day.toDate())
                // const fd = new Date(fald.first)
                // const ld = new Date(fald.last)

                const darrf = fald.first.split('-')
                const darrl = fald.last.split('-')

                const fd = getCorrectDateFormat({
                    day: darrf[1],
                    month: darrf[0],
                    year: darrf[2]
                })
                const ld = getCorrectDateFormat({
                    day: darrl[1],
                    month: darrl[0],
                    year: darrl[2]
                })
                if(trDay.getTime() <= ld.getTime() && trDay.getTime() >= fd.getTime() ){
                    // console.log(transaction.name);
                    
                    if(meiruko.length > 0){
                        let gate = true
                        meiruko.forEach(( mei, index ) => {
                            // console.log(transaction.name, mei.name, mei.name.toLowerCase().includes(transaction.name.toLowerCase()) || transaction.name.toLowerCase().includes(mei.name.toLowerCase()));
                            if(mei.name.toLowerCase().includes(transaction.name.toLowerCase()) || transaction.name.toLowerCase().includes(mei.name.toLowerCase())){
                                selectedMonthObj && selectedMonthObj.forEach((mo, zindex) => {
                                    const zfald = getFirstAndLastDateFromWeek(zindex, selectedMonth, selectedYear)
                                    const ztrDay = new Date(transaction.day.toDate())
                                    // const zfd = new Date(zfald.first)
                                    // const zld = new Date(zfald.last)

                                    const zdarrf = zfald.first.split('-')
                                    const zdarrl = zfald.last.split('-') 

                                    const zfd = getCorrectDateFormat({
                                        day: zdarrf[1],
                                        month: zdarrf[0],
                                        year: zdarrf[2]
                                    })
                                    const zld = getCorrectDateFormat({
                                        day: zdarrl[1],
                                        month: zdarrl[0],
                                        year: zdarrl[2]
                                    })
                                    if(ztrDay.getTime() <= zld.getTime() && ztrDay.getTime() >= zfd.getTime()){
                                        meiruko[index].weekly[zindex] = (isNaN(parseFloat(meiruko[index].weekly[zindex])) ? 0 : parseFloat(meiruko[index].weekly[zindex])) + parseFloat(transaction.amount)  
                                    }         
                                })
                                
                                meiruko[index].amount = parseFloat(meiruko[index].amount) + parseFloat(transaction.amount)
                                gate = false
                                return
                            }
                        })
                        if(gate){
                            let wobj = []
                            selectedMonthObj && selectedMonthObj.forEach((mo, zindex) => {
                                const zfald = getFirstAndLastDateFromWeek(zindex, selectedMonth, selectedYear)
                                // const ztrDay = new Date(transaction.day.toDate())
                                // const zfd = new Date(zfald.first)
                                // const zld = new Date(zfald.last)

                                const zdarrf = zfald.first.split('-')
                                const zdarrl = zfald.last.split('-') 

                                const zfd = getCorrectDateFormat({
                                    day: zdarrf[1],
                                    month: zdarrf[0],
                                    year: zdarrf[2]
                                })
                                const zld = getCorrectDateFormat({
                                    day: zdarrl[1],
                                    month: zdarrl[0],
                                    year: zdarrl[2]
                                })

                                if(trDay.getTime() <= zld.getTime() && trDay.getTime() >= zfd.getTime()){
                                    wobj[zindex] = (isNaN(parseFloat(wobj[zindex])) ? 0 : parseFloat(wobj[zindex])) + parseFloat(transaction.amount) 
                                }
                            })
                            meiruko.push({
                                name: transaction.name,
                                amount: parseFloat(transaction.amount),
                                weekly: wobj
                            })
                        }
                    }else{
                        let wobj = []
                        selectedMonthObj && selectedMonthObj.forEach((mo, zindex) => {
                            const zfald = getFirstAndLastDateFromWeek(zindex, selectedMonth, selectedYear)
                            // const ztrDay = new Date(transaction.day.toDate())
                            // const zfd = new Date(zfald.first)
                            // const zld = new Date(zfald.last)

                            const zdarrf = zfald.first.split('-')
                            const zdarrl = zfald.last.split('-') 

                            const zfd = getCorrectDateFormat({
                                day: zdarrf[1],
                                month: zdarrf[0],
                                year: zdarrf[2]
                            })
                            const zld = getCorrectDateFormat({
                                day: zdarrl[1],
                                month: zdarrl[0],
                                year: zdarrl[2]
                            })

                            if(trDay.getTime() <= zld.getTime() && trDay.getTime() >= zfd.getTime()){
                                wobj[zindex] = parseFloat( transaction.amount )
                            }
                        })
                        meiruko.push({
                            name: transaction.name,
                            amount: parseFloat(transaction.amount),
                            weekly: wobj
                        })
                        return
                    }
                }
            })

            // console.log(transactions.length);
            // console.log(meiruko);
            
            return meiruko.sort((a,b) => b.amount - a.amount).splice(0, 5);
    }

    const handleCliiiiick =(e)=>{
        setGoalReach(e.target.value)
    }





    return (
        <>
        {goalNotHere && 
            <button 
            onClick={addBlankGoal}
            style={isMobile ? {
                fontSize: '1.4em',
                padding: '10px 20px',
                marginTop: '50px',
                maxWidth: '90%'
            } : {
                fontSize: '2em',
                padding: '20px 50px'
            }}
            >Ready to Start the Month?</button>
        }
        {!goalNotHere && 
        <div className="dashboard-container-content flex-col-center-start">
            <div className="monthly-summary-nav flex-row-center-center">
                <span onClick={()=> setisSummaryOpen(false)} className={!isSummaryOpen ? "active" : ""}>Monthly Budget</span>
                <div></div>
                <span onClick={()=> setisSummaryOpen(true)} className={isSummaryOpen ? "active" : ""}>Monthly Summary</span>
            </div>
            
            {!error && !isSummaryOpen && documents && documents.length &&
                <div className="monthly-summary-content flex-col-center-start">
                    <TransactionMonthlySummaryList 
                        title="Monthly Income"
                        type="monthlyIncome"
                        data={documents[0].monthlyIncome}
                        id={documents[0].id}
                    />
                    <br /> <br />
                    <TransactionMonthlySummaryList 
                        title="Monthly Expense"
                        type="monthlyExpense"
                        data={documents[0].monthlyExpense}
                        id={documents[0].id}
                    />
                    <br /><br />
                    <span className={isMobile ? "title flex-row-center-between" : "title flex-row-center-center"}>Goals and Forecast {isGoalChanged && <>&nbsp; <button onClick={saveGoal}>Save</button></>} </span>
                    <br />
                    <div className="goal-field flex-col-start-start">
                        <span>Which expenses do you plan to cut to save money?</span>
                        <textarea placeholder="eg. ( cigarette, alcohol, game pass )" value={goalPlan} onChange={e => setGoalPlan(e.target.value)}></textarea>
                    </div>
                    <br />
                    <div className="goal-field flex-col-start-start">
                        <span>How can you reach your goals?</span>
                        <textarea placeholder="eg. ( lessen smoking, Stop spending on games )" value={goalReach} onChange={ handleCliiiiick } ></textarea>
                    </div>
                    <br />
                    <div className={`goal-field-amount ${isMobile ? "flex-col-center-start" : "flex-row-end-between"}`}>
                        <span>How much do you want to save? ($)</span>
                        <input type="number" value={goalForecastSave} onChange={e => setGoalForecastSave(e.target.value)} />
                    </div>
                    <br />
                    <div className="goal-field-summary flex-col-start-start">
                        <span>How much momey do you have available this month?</span>
                        <div className="widget-con flex-row-center-between">
                            <div className={`widget ${isMobile ? "flex-col-center-between" : "flex-col-center-center"}`}>
                                <span className="title">Total Income</span>
                                <span className="amount">${totalIncome}</span>
                            </div>
                            <div className={`widget ${isMobile ? "flex-col-center-between" : "flex-col-center-center"}`}>
                                <span className="title">Fixed Expense</span>
                                <span className="amount">${totalExpense}</span>
                            </div>
                            <div className={`widget ${isMobile ? "flex-col-center-between" : "flex-col-center-center"}`}>
                                <span className="title">Forecast Savings</span>
                                <span className="amount">${goalForecastSave}</span>
                            </div>
                        </div>
                        
                    </div>
                    
                    <br />
                    <div className="goal-field-amount flex-col-center-center">
                        <span>Cash Available($)</span>
                        <span className="total">${totalIncome - totalExpense - goalForecastSave}</span>
                    </div>
                </div>
            }
            {authIsReady && !error && isSummaryOpen && 
                <div className="monthly-summary-content flex-col-center-start">
                    
                    <TransactionMonthlySummarySignificant data={getSignificantExpenses()} />

                    <div className="summary-chart-grid4">
                        <Chart
                            width={isMobile ? "100vw" : "40vw"}
                            height={isMobile ? "100vw" : "40vw"}
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={getChartDataPerType('essential')}
                            options={{
                                title: 'Essential Expense Monthly Summary -Weekly View',
                                chartArea: { width: isMobile ? "95%" : "90%" },
                                isStacked: true,
                                colors: getCategoryChartColors('essential'),
                                style: {
                                    opacity: 0.5
                                }
                            }}
                        />
                        <Chart
                            width={isMobile ? "100vw" : "40vw"}
                            height={isMobile ? "100vw" : "40vw"}
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={getChartDataPerType('optional')}
                            options={{
                                title: 'Optional Expense Monthly Summary -Weekly View',
                                chartArea: { width: isMobile ? "95%" : "90%" },
                                isStacked: true,
                                colors: getCategoryChartColors('optional'),
                                style: {
                                    opacity: 0.5
                                }
                            }}
                        />
                        <Chart
                            width={isMobile ? "100vw" : "40vw"}
                            height={isMobile ? "100vw" : "40vw"}
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={getChartDataPerType('leisure')}
                            options={{
                                title: 'Leisure Expense Monthly Summary -Weekly View',
                                chartArea: { width: isMobile ? "95%" : "90%" },
                                isStacked: true,
                                colors: getCategoryChartColors('leisure'),
                                style: {
                                    opacity: 0.5
                                }
                            }}
                        />
                        <Chart
                            width={isMobile ? "100vw" : "40vw"}
                            height={isMobile ? "100vw" : "40vw"}
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={getChartDataPerType('extras')}
                            options={{
                                title: 'Extras Expense Monthly Summary -Weekly View',
                                chartArea: { width: isMobile ? "95%" : "90%" },
                                isStacked: true,
                                colors: getCategoryChartColors('extras'),
                                style: {
                                    opacity: 0.5
                                }
                            }}
                        />
                    </div>
                    
                    <Chart
                        width={isMobile ? "90vw" : "960px"}
                        height={isMobile ? "90vw" : "960px"}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={getMainChartData()}
                        options={{
                            title: 'Monthly Expense Summary Weekly View',
                            chartArea: { width: isMobile ? "95%" : "90%" },
                            isStacked: false,
                            // hAxis: {
                            //     title: 'Monthly Expense',
                            //     minValue: 0,
                            // },
                            // vAxis: {
                            // title: 'City',
                            // },
                            // colors: ['#cccccc', '#aaaaaa', '#919191', '666666', '434343']
                            colors: ['#fdcb30', '#dbaf2a', '#c29b26', 'a78521', '866c1c']
                        }}
                    />
                </div>
            }
        </div>
    
        }
        </>
    )
}
