import './HowToUse.css'
import { useIsMobile } from '../../hooks/useIsMobile'


export default function HowToUse() {
    const { isMobile } = useIsMobile()

    return (
        <div className="howtouse-container flex-col-center-start">
            {isMobile && <button className="howtouse-btn"><a href="/docs/Lezzt_Finance_Manual.pdf#page=1&zoom=150">Can't view? Download PDF Manual instead</a></button>}
            <div id="adobe-dc-view" className="flex-col-center-center" style={{
                width: '100vw',
                height: '90vh',
                maxHeight: '100vh',
                overflow: "hidden"
            }}>
                <object className="flex-col-center-start pdftest" frameBorder="0" scrolling="yes"  data="/docs/Lezzt_Finance_Manual.pdf#page=1&zoom=150" type="application/pdf" width="100%" height="100%">
                    <p className="pdftestError"> <br/> Unfortunately... this feature doesnt work on mobile. <a href="/docs/Lezzt_Finance_Manual.pdf#page=1&zoom=150">Download PDF</a> instead</p>
                </object>

                {/* <embed className="pdftest" src="/docs/Lezzt_Finance_Manual.pdf#page=1&zoom=150" /> */}
                {/* <iframe frameborder="0" scrolling="no" className="pdftest" src="/docs/Lezzt_Finance_Manual.pdf#page=1&zoom=150" /> */}
                
            </div>
        </div>
    )
}
