import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

//components
// import Home from './pages/home/Home'
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import Navbar from './components/Navbar'
import NewDashboard from './pages/dashboard/NewDashboard'

import { useAuthContext } from './hooks/useAuthContext'
import HowToUse from './pages/howtouse/HowToUse'
import Website from './pages/website/Website'
import Terms from './pages/terms/Terms'
import Contact from './pages/contact/Contact'




function App() {
  const { authIsReady, user } = useAuthContext()


  return (
    
    <div className="App">
    {authIsReady && 
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={ user !== null ? <NewDashboard /> :  <Navigate to="/login" /> } />
          {/* <Route path="/" element={ user !== null ? <CompatDashboard /> :  <Navigate to="/login" /> } /> */}
          {/* <Route path="/" element={ user !== null ? <TestDashboard /> :  <Navigate to="/login" /> } /> */}
          <Route path="/howtouse" element={ <HowToUse /> } />
          <Route path="/about" element={ <Website />} />
          <Route path="/termsandconditions" element={ <Terms />} />
          <Route path="/contact" element={ <Contact />} />
          <Route path="/login" element={ user !== null ? <Navigate to="/" /> : <Login /> } />
          <Route path="/signup" element={ user !== null ? <Navigate to="/" /> : <Signup />  } />
          <Route path="*" element={ <Navigate to="/" />  } />
        </Routes>
      </BrowserRouter>
    }
    </div>
  );
}

export default App
