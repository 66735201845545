import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'

// const firebaseConfig = {
//   apiKey: "AIzaSyAkQFIJrlYKO7tsXrU2snxKOH5uTdfH74Q",
//   authDomain: "react-finance-app-35345.firebaseapp.com",
//   projectId: "react-finance-app-35345",
//   storageBucket: "react-finance-app-35345.appspot.com",
//   messagingSenderId: "761732071496",
//   appId: "1:761732071496:web:aee316da2e583d3df9587d"
// };
const firebaseConfig = {
  apiKey: "AIzaSyC72UFgvYTQgGyOeq-nBZpDV0LTT749zQI",
  authDomain: "lezzt-322804.firebaseapp.com",
  projectId: "lezzt-322804",
  storageBucket: "lezzt-322804.appspot.com",
  messagingSenderId: "83449940468",
  appId: "1:83449940468:web:f7c98421fabf3ebfed91a1",
  measurementId: "G-2QFZ5Z0L1B"
};



// init firebase
firebase.initializeApp(firebaseConfig)

//init service
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()


// google provider
const googleProvider = new firebase.auth.GoogleAuthProvider()

// facebook provider
const facebookProvider = new firebase.auth.FacebookAuthProvider();



// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp, googleProvider, facebookProvider }