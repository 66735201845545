import { useFirestore } from "../../../hooks/useFirestore"

import { rngPassword } from "../../../helper/helper"
import { useState } from "react"


export default function MobileDataWidgetCardContentWidget({ transaction }) {
    const { deleteDocument, response } = useFirestore('transactions')
    const handleDeleteClick =id=>{
        console.log(response)
        deleteDocument(id)
    }
    const [isActive, setIsActive] = useState(false)

    return (
        <div key={rngPassword()} onClick={()=> setIsActive(!isActive)} className="widget flex-row-end-between">
            <div className={`details ${isActive ? "flex-col-start-center" : "flex-row-center-between"}`}>
                <span>{transaction.name}</span>
                <p>${transaction.amount}</p>
            </div>
            {isActive && <img onClick={()=> handleDeleteClick(transaction.id)} src="/icons/delete_black_48dp.svg" alt="" />}
        </div>
    )
}

