export default function TransactionMonthlySummaryListWidget({ handleDeleteClick, data }) {
    const { name, amount } = data

    return (
        <div className="widget flex-row-center-between">
            <span className="title">{name}</span>
            <span className="amount flex-row-center-between">
                ${amount} 
                &nbsp; 
                <img onClick={handleDeleteClick} src="/icons/delete_black_48dp.svg" alt="" /> 
            </span>
        </div>
    )
}
