import { useState, useEffect, useMemo } from "react"
import { getCorrectDateFormat, getSubCategory } from "../../../helper/helper"
import { useSwipe } from "../../../hooks/useSwipe"
import { useAuthContext } from "../../../hooks/useAuthContext"

export default function MobileTransactionWeeklyReport({ documents }) {
    
    const {  selectedMonthObj: currentMonthObj, selectedWeek: currentWeek } = useAuthContext()

    const [subCat, setSubCat] = useState(null)
    const [transactions, setTransactions] = useState([])
    const [transactionsTotal, setTransactionsTotal] = useState([])
    const [type, setType] = useState('essential')
    
    const [essentialTotal, setEssentialTotal] = useState(0)
    const [optionalTotal, setOptionalTotal] = useState(0)
    const [leisureTotal, setLeisureTotal] = useState(0)
    const [extrasTotal, setExtrasTotal] = useState(0)

    const [selType, setSelType] = useState(0)

    // const typeList = 

    const typeList = useMemo(() => ['essential', 'optional', 'leisure', 'extras'], []);
    // const typeList = ['essential', 'optional', 'leisure', 'extras'];

    
    const { swipeDirection, Swiper } = useSwipe() 

    
    useEffect(() => {
        setSubCat(getSubCategory(type))


    }, [type])

    function swipeRight(selType, typeList){
        if(selType >= (typeList.length - 1)){
            setSelType(0)
        }else{
            setSelType(ps => ps + 1)
        }
    }
    function swipeLeft(selType, typeList){
        if(selType <= 0){
            setSelType(typeList.length - 1)
        }else{
            setSelType(ps => ps - 1)
        }
    }

    useEffect(() => {
        if(swipeDirection){
            if(swipeDirection.direction){
                if(swipeDirection.direction === 'right'){
                    // next
                    swipeRight(selType, typeList)

                }else if(swipeDirection.direction === 'left'){
                    // prev
                    swipeLeft(selType, typeList)
                }
                // console.log(swipeDirection.direction, selType)
            }
        }

    }, [swipeDirection]);

    useEffect(() => {
        setType(typeList[selType])
    }, [selType, typeList])

    useEffect(() => {
        setTransactions([])
        setTransactionsTotal(0)
        

        let trColl = [];
        documents && documents.map(doc => {
            const docDay = new Date(doc.day.toDate())
            const darrsd = currentMonthObj[currentWeek][0].split('-')
            const darred = currentMonthObj[currentWeek][6].split('-')
            const sd = getCorrectDateFormat({
                day: darrsd[1],
                month: darrsd[0],
                year: darrsd[2]
            })
            const ed = getCorrectDateFormat({
                day: darred[1],
                month: darred[0],
                year: darred[2]
            })

            if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime() && doc.category === type){
                trColl.push(doc)
            }
            return null
        })

        
        // console.log('trColl', type, trColl)
        trColl && trColl.map(tr => {
            if(tr.category === type){
                setTransactionsTotal(ps => ps + parseFloat(tr.amount) )
            }
            return null
        })

        
        setTransactions(trColl)

    }, [documents, type, currentMonthObj, currentWeek])

    useEffect(() => {
        setEssentialTotal(0)
        setOptionalTotal(0)
        setLeisureTotal(0)
        setExtrasTotal(0)
        

        let trColl = [];
        documents && currentMonthObj && currentMonthObj.length && documents.map(doc => {
            const docDay = new Date(doc.day.toDate())
            // const sd = new Date(currentMonthObj[currentWeek][0])
            // const ed = new Date(currentMonthObj[currentWeek][6])

            const darrsd = currentMonthObj[currentWeek][0].split('-')
            const darred = currentMonthObj[currentWeek][6].split('-')
            const sd = getCorrectDateFormat({
                day: darrsd[1],
                month: darrsd[0],
                year: darrsd[2]
            })
            const ed = getCorrectDateFormat({
                day: darred[1],
                month: darred[0],
                year: darred[2]
            })

            if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime() && doc.category === type){
                trColl.push(doc)
            }

            if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime() && doc.category === 'essential'){
                // trColl.push(doc)
                setEssentialTotal(ps => ps + parseFloat(doc.amount))
            }
            if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime() && doc.category === 'optional'){
                // trColl.push(doc)
                setOptionalTotal(ps => ps + parseFloat(doc.amount))
            }
            if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime() && doc.category === 'leisure'){
                // trColl.push(doc)
                setLeisureTotal(ps => ps + parseFloat(doc.amount))
            }
            if(docDay.getTime() >= sd.getTime() && docDay.getTime() <= ed.getTime() && doc.category === 'extras'){
                // trColl.push(doc)
                setExtrasTotal(ps => ps + parseFloat(doc.amount))
            }
            return null
        })
        

    }, [documents, currentMonthObj, currentWeek, type])


    return (
        <>
            <span>WEEKLY SUMMARY REPORTS</span>
                        
            <Swiper className={`mobile-date-widget-card-report ${type}`}>
                <div className="header  flex-row-center-between">
                    <span>{type}</span>
                </div>
                <div className="content">
                    { transactions && subCat && subCat.data.map(sc =>  {
                        let na = 0
                        transactions.map(tr => {
                            if(tr.subcategory === sc.id){
                                na += parseFloat(tr.amount)
                            }
                            return null
                        })
                        return (
                            <div key={sc.id} className="widget flex-row-end-between">
                                <span>{sc.name}</span>
                                <p>${na}</p>
                            </div>
                        )
                    })}
                    
                   
                    <div className="widget total flex-row-end-between">
                        <span>Total</span>
                        <p>${transactionsTotal}</p>
                    </div>

                </div>
            </Swiper>

            <div className="mdwcr-handler flex-row-center-between">
                <div onClick={()=> setType('essential')} className={`widget flex-row-center-center ${type === 'essential' ? "active" : ""} essential`}>${essentialTotal}</div>
                <div onClick={()=> setType('optional')} className={`widget flex-row-center-center ${type === 'optional' ? "active" : ""} optional`}>${optionalTotal}</div>
                <div onClick={()=> setType('leisure')} className={`widget flex-row-center-center ${type === 'leisure' ? "active" : ""} leisure`}>${leisureTotal}</div>
                <div onClick={()=> setType('extras')} className={`widget flex-row-center-center ${type === 'extras' ? "active" : ""} extras`}>${extrasTotal}</div>
            </div>
            
        </>
    )
}
