import { useFirestore } from "../../hooks/useFirestore"

export default function TransactionWidgetContent({ transactions, isClosed, id }) {
    const { deleteDocument, response } = useFirestore('transactions')
    const handleDeleteClick =id=>{
        console.log(response)
        deleteDocument(id)
    }
    return (
        <div key={id} className="trans flex-col-center-start">
            {transactions && !isClosed && transactions.map(transaction => 
                <div key={transaction.id} className="widget flex-col-start-start">
                    <span className="title">{transaction.name}</span>
                    <span className="amount flex-row-center-between">
                        ${transaction.amount} 
                        <img onClick={()=> handleDeleteClick(transaction.id)} src="/icons/delete_black_48dp.svg" alt="" />
                    </span>
                </div>
            )}
            {!transactions.length && !isClosed &&
                <div key="empty" className="widget flex-col-start-start">
                    <span className="title">Empty</span>
                </div>
            }
            
        </div>
    )
}
