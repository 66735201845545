import { useEffect, useState } from "react"
import MobileDataWidgetCardContent from "./MobileDataWidgetCardContent";
import Modal from "../../../helper/Modal";
import { getCorrectDateFormat, getSubCategory } from "../../../helper/helper";
import { useFirestore } from "../../../hooks/useFirestore";
import { timestamp } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";


export default function MobileDateWidgetCard({ transactions, type, closeAllTransactions, selectedDate }) {
    const { addDocument, response } = useFirestore('transactions')
    const { user } = useAuthContext()

    const [isClosed, setIsClosed] = useState(closeAllTransactions)

    const [showModal, setShowModal] = useState(false);
    const [subCatList, setSubCatList] = useState(null);

    const [selSubCat, setSelSubCat] = useState("na");
    const [selTitle, setSelTitle] = useState("");
    const [selAmount, setSelAmount] = useState("");
    

    // console.log(type, transactions)

    useEffect(() => {
        
        setIsClosed(closeAllTransactions)
    }, [closeAllTransactions]);

    useEffect(() => {
        if(!showModal){
            setSelSubCat("na")
            setSelTitle('')
            setSelAmount('')
        }
    }, [showModal])

    useEffect(() => {
        setSubCatList(getSubCategory(type))

    }, [type]);

    useEffect(() => {
        // effect
        
        if(response.success){
            setSelSubCat('na')
            setSelTitle('')
            setSelAmount('')
            setShowModal(false)
        }
    }, [response.success]);


    const handleSubmit =e=>{
        e.preventDefault();

        console.log({
            uid: user.uid,
            category: type,
            subcategory: selSubCat,
            name: selTitle,
            amount: selAmount,
            day: timestamp.fromDate(getCorrectDateFormat({
                day: selectedDate.split('-')[1],
                month: selectedDate.split('-')[0],
                year: selectedDate.split('-')[2]
            }))
        })

        addDocument({
            uid: user.uid,
            category: type,
            subcategory: selSubCat,
            name: selTitle,
            amount: selAmount,
            day: timestamp.fromDate(getCorrectDateFormat({
                day: selectedDate.split('-')[1],
                month: selectedDate.split('-')[0],
                year: selectedDate.split('-')[2]
            }))
        })
    }

 

    return (
        <>
        {showModal && 
            <Modal 
                closeModal={()=> setShowModal(false)}
            >
                <h3>Add Transaction to {subCatList.title}</h3>
                <br />
                <form onSubmit={handleSubmit}>
                    <div className="field">
                        <span>Choose Sub Category</span>
                        <select onChange={e=> setSelSubCat(e.target.value)} value={selSubCat} required>
                            <option value="na" disabled>Please Select One</option>
                            {subCatList && subCatList.data.map(sc => <option key={sc.id} value={sc.id}>{sc.name}</option> )}
                        </select>
                    </div>
                    {subCatList && subCatList.data.map(sc =>  <p key={sc.id}>{sc.id === selSubCat ? sc.description : ""}</p>  )}
                    <div className="field">
                        <span>Title</span>
                        <input value={selTitle} onChange={e=> setSelTitle(e.target.value)} type="text" required />
                    </div>
                    <div className="field">
                        <span>Amount</span>
                        <input value={selAmount} onChange={e=> setSelAmount(e.target.value)} type="number" required/>
                    </div>
                    <button>Save</button>
                </form>
            </Modal>
        }
        <div className={`mobile-date-widget-card ${type}`}>
            <div className="header  flex-row-center-between">
                <span onClick={()=> setIsClosed(!isClosed)}>{type} &nbsp; {transactions.length}</span>
                <img 
                    onClick={()=> setShowModal(true)} 
                    src="/icons/add_circle_white_48dp.svg" 
                    alt="" 
                />
            </div>
            {!isClosed && 

                <MobileDataWidgetCardContent 
                    transactions={transactions}
                    isClosed={isClosed}
                    id={transactions.id}
                />
            }
            

        </div>
        </>
    )
}
