import {  useState } from "react"
import ReactDOM from 'react-dom'
import '../helper/Toast.css'


export default function usePrompt() {
    // usage >> 
    // import { useToast } from '../../hooks/useToast'
    // const { showToast, toast } = useToast(2000)
    // {toast} --> use this anywhere in your UI

    const [prompt, setPrompt] = useState(null)

    function promptChoice({ title }){
        return new Promise((resolve, reject) => {
            const handleYesClick =()=>{
                setPrompt(null)
                resolve()
            }
            const handleNoClick =()=>{
                setPrompt(null)
                reject()
            }
    
            setPrompt(ReactDOM.createPortal((
                <div id="global-prompt" className="flex-col-center-center">
                    <div className="container flex-col-center-center">
                        <h3 className="title">{title}</h3>
                        <div className="choice flex-row-center-even">
                            <button onClick={handleYesClick}>Yes</button>
                            <button onClick={handleNoClick}>No</button>
                        </div>
                    </div>
                </div>
            ), document.getElementById('prompt')))

        });
    }

    function promptPassword({ title }){
        return new Promise((resolve, reject) => {

            const handleSubmit =e=>{
                console.log(e)
                setPrompt(null)
                resolve(e.target[0].value)
            }
    
            setPrompt(ReactDOM.createPortal((
                <div id="global-prompt" className="flex-col-center-center" >
                    <div className="container flex-col-center-center">
                        <h3 className="title">{title}</h3>
                        <form onSubmit={handleSubmit} className="password flex-row-center-even">
                            <input type="password" />
                            <button>Submit</button>
                        </form>
                    </div>
                </div>
            ), document.getElementById('prompt')))

        });
    }

    return { prompt, promptChoice, promptPassword  }
}
